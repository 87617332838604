import { StyleSheet, View, TextInput, Platform } from "react-native";
import Icon from "./icons/CustomIcon";
import { debounce } from "lodash";

export default function SearchInput({ onKeyChange, key}) {


  return (
    <View style={styles.search_box}>
      <Icon
        style={styles.search_icon}
        name="search"
        size={17}
        color="#000000"
      />

      <TextInput
        style={styles.search_input}
        placeholder={"Start typing to search"}
        placeholderTextColor="#646464" 
        onChangeText={debounce((d) => {
          onKeyChange(d)
        }, 1500)}
        defaultValue={key}
        keyboardType="visible-password"
        autoCorrect={false}
        autoComplete="off"
      ></TextInput>
    </View>
  );
}

const styles = StyleSheet.create({
  search_box: {
    position: "relative",
    display: "flex",
    borderWidth: 1,
    borderRadius: 15,
    height: 45,
    borderColor: "black",
    width: "100%",
    marginTop: Platform.OS === 'web' ? 5 : 3
  },
  search_input: {
    paddingLeft: 43,
    paddingRight: 15,
    borderRadius: 15,
    height: 45,
    fontFamily: 'SofiaPro',
    lineHeight: 15
  },
  search_icon: {
    position: "absolute",
    left: 20,
    zIndex: 2,
    top: 13,
  },
});
