import { Text } from 'react-native';
import { useLinkTo } from "@react-navigation/native";
import Animated, { SequencedTransition } from 'react-native-reanimated';
import { useCallback, useEffect, useState, memo} from "react";
import unionBy from 'lodash/unionBy';
import { usePodcastsLibrary } from "../hooks/index.app";
// import { useSearch } from "@podcasts/hooks";
import PodcastListItem from "./PodcastListItem";
import ApplyNowCtaItem from "./ApplyNowCtaItem";
import RefereAFriendCta from "./RefereAFriendCta";
// import FlatListWithCta from "./FlatListWithCta";

const FlatListWithCta = ({ isAuthenticated, ...rest }) => {
  return (
    <Animated.FlatList
      {...rest}
      itemLayoutAnimation={SequencedTransition}
      ListFooterComponent={
        isAuthenticated ? <RefereAFriendCta  headphones_img={false}/> : <ApplyNowCtaItem headphones_img={false} />
      }
    />
  );
};

export default function PodcastList({ params, isFetching, lastPage, currentPage = 1, podcasts, noResult, onSearch, podcastSubTitle = 'topic' }) {
  const linkTo = useLinkTo();
  const { isSavedPodcast, togglePodcastLibrary, isAuthenticated } =
    usePodcastsLibrary();
  const [fetchingPage, setPage] = useState(1);
  const [loadedPage, setLoadedPage] = useState([]);
  const [data, setData] = useState([]);

  // const [localParams, setParams] = useState(params);

  const searchFunc = useCallback((params, force = false) => {
    const page = params.page;
    if (loadedPage.indexOf(page) === -1 || force) {
      setLoadedPage((p) => [...p, page]);
      onSearch(params);
    }
  }, [loadedPage]);

  useEffect(() => {
    setData([]);
    setLoadedPage([]);
    setPage(1);
    searchFunc({...params, page: 1}, true);
  }, [params.search, params.speaker, params.topic, params.course]);

  // useEffect(() => {
  //   if (!isEqual(params, localParams)) {
  //     setData([]);
  //     setParams(params);
  //     setPage(1);
  //   }
  // }, [params]);

  useEffect(() => {
    if (podcasts.length > 0 && !isFetching) {
      setData((p) => unionBy(p, podcasts, 'id'));
      // if (data.findIndex((item) => item.id === podcasts[0].id) === -1) {
      //   setData((p) => uniqBy([...p, ...podcasts]));
      // }
      if (fetchingPage <= lastPage) {
        let page = fetchingPage;
        if (currentPage === fetchingPage) {
          page = page + 1;
        }
          searchFunc({...params, page: page});
        
      }
    };
  }, [podcasts, currentPage, lastPage, fetchingPage]);

  const onSavePodcast = useCallback((item) => {
    togglePodcastLibrary(item);
  }, [isAuthenticated]);

  const onEndReached = useCallback(() => {
    if (fetchingPage <= lastPage) {
      setPage((prev) => prev + 1);
      // onSearch({...params, page: fetchingPage + 1});
    }
  }, [params, fetchingPage, lastPage]);

  const renderItem = useCallback(({ item, index }) => (
    <PodcastListItem
      index={index}
      subTitle={podcastSubTitle}
      isAuthenticated={isAuthenticated}
      key={index}
      onPress={() => {
        linkTo(`/podcasts/${item.slug}`);
      }}
      onSave={() => onSavePodcast(item)}
      saved={isSavedPodcast(item.id)}
      item={item}
    />
  ), []);
  // console.log(isAuthenticated,'isAuthenticated2')
  return (
    <FlatListWithCta
      data={data}
      ListHeaderComponent={noResult && data.length === 0 && !isFetching ? <Text style={{ flex: 1, textAlign: 'center', padding: 20, fontFamily: 'Poppins_500Medium'}}>No Results. Try a new search</Text> : <></>}
      isAuthenticated={isAuthenticated}
      keyExtractor={item => item.id.toString()}
      renderItem={renderItem}
      windowSize={15}
      ListFooterComponentStyle={{ marginHorizontal: 20 }}
      scrollToIndex={0}
      onEndReachedThreshold={0.8}
      onEndReached={onEndReached}
    />
  );
}
