import { Platform, View } from "react-native";

const shareMenuItem = {
    icon: "share-light",
    sizeIcon: 18,
    name: "Share",
    path: "",
    auth_required: false,
  }

const Share = ({ podcast }) => {
  let _Share;
  if (Platform.OS === "web") {
    _Share = require("./WebShare.js").default;
  } else {
    _Share = require("./NativeShare.js").default;
  }
  return (
    <View>
      <_Share {...shareMenuItem} podcast={podcast} />
    </View>
  );
};
export default Share;
