import { StyleSheet, Text, Pressable, View, Dimensions, Platform } from "react-native";
import xor from 'lodash/xor';
import cloneDeep from 'lodash/cloneDeep';
import PodcastList from "../../components/PodcastList2";
import { useDispatch, useSelector } from "react-redux";
import { useState, useLayoutEffect, useCallback, useEffect } from "react";
import { getFilters, getSearch, resetSearch } from "@podcasts/store/search"
import { searchSelector } from "@podcasts/store/search"
import { useIsFocused } from "@react-navigation/native";
import SvgIcon from "../../components/svg/icon";
import SearchFiltersModal from "./SearchFiltersModal";
import SearchInput from "../../components/SearchInput";

const windowWidth = Dimensions.get('window').width;

const Tag = ({ title, id, onRemove, filterType, circle = false }) => (
  <View style={{...styles.tagContainer, ...(circle ? {borderRadius: 9999, width: 35, height: 35} : {}) }} key={id}>
    <Text numberOfLines={1} ellipsizeMode='tail' style={styles.tagText}>{title}</Text>
    {!circle && <Pressable onPress={() => onRemove(id, filterType)}><SvgIcon name={"CloseCross"} width="12" height="12"/></Pressable>}
  </View>
);
export default function SearchPage({ navigation }) {
  // const { filters,
  //         onSearchKeyChange,
  //         toggleFilter } = useSearchPage();
    
  const [visible, setVisible] = useState(false);
  const isFocused = useIsFocused();
  const [query, setQuery] = useState({
    per_page: 15,
    order_by: "title",
    order: "asc",
    page: 1,
    search: "",
    course: [],
    topic: [],
    speaker: []
  });
  const dispatch = useDispatch();

  const [selectedFilters, setSelectFilters] = useState({ speaker: [], course: [], topic: [] });
  const [selectedFiltersObj, setSelectFiltersObj] = useState({ speaker: [], course: [], topic: [] });

  const {
    courses,
    speakers,
    topics,
    result: { data = [], last_page, current_page },
    loading
  } = useSelector(searchSelector);

  const onSearch = useCallback((params) => {
    const q = {...query, ...params};
    setQuery(q);
    dispatch(getSearch(q));
  }, [query]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerTitle: (props) => <View style={{  width: windowWidth - (Platform.OS === 'web' ? 91 : 86), marginLeft: 8 }}>
        <SearchInput onKeyChange={(d) => onSearch({search: d})} search={query.search} />
        </View>,
    })
  },[]);

  useEffect(() => {
    return () => {
      console.log('Unmount');
      dispatch(resetSearch());
    };
  }, []);

  const toggleFilter = ({ type, id }) => {
    setSelectFilters({ ...selectedFilters, [type]: xor(selectedFilters[type], [id]) });
  };

  const triggerFilter = useCallback(() => {
    const q = cloneDeep(query);
    q.speaker = selectedFilters.speaker;
    q.topic = selectedFilters.topic;
    q.course = selectedFilters.course;
    delete q.page;
    delete q.per_page;
    delete q.order;
    delete q.order_by;
    dispatch(getFilters(q));
  }, [query, selectedFilters]);

  useEffect(() => {
    triggerFilter();
  }, [selectedFilters]);

  const applyFilters = useCallback(() => {
    const q = query;
    q.page = 1;
    q.speaker = selectedFilters.speaker;
    q.topic = selectedFilters.topic;
    q.course = selectedFilters.course;
    const obj = {};
    if (selectedFilters.speaker.length > 0) {
      obj.speaker = selectedFilters.speaker.map((id) => speakers.find((s) => s.id === id));
    }
    if (selectedFilters.topic.length > 0) {
      obj.topic = selectedFilters.topic.map((id) => topics.find((s) => s.id === id));  
    }
    if (selectedFilters.course.length > 0) {
      obj.course = selectedFilters.course.map((id) => courses.find((s) => s.id === id));
    }
    setSelectFiltersObj(obj);
    onSearch(q);
  }, [query, selectedFilters]);

  const removeFilter = useCallback((id, filterType) => {
    const newSelectedFilters = { ...selectedFilters, [filterType]: selectedFilters[filterType].filter((s) => s !== id) };
    const newSelectedFiltersObj = { ...selectedFiltersObj, [filterType]: selectedFiltersObj[filterType].filter((s) => s.id !== id) };
    setSelectFilters(newSelectedFilters);
    setSelectFiltersObj(newSelectedFiltersObj);
    const q = query;
    q.page = 1;
    q.speaker = newSelectedFilters.speaker;
    q.topic = newSelectedFilters.topic;
    q.course = newSelectedFilters.course;
    setQuery(q);
  }, [selectedFilters, selectedFiltersObj]);

  return (
    <>
      <View style={styles.filter_wrapp}>
        <Pressable
          style={styles.filter_link}
          onPress={() => {
            setVisible(true);
          }}
        >
          <Text style={styles.link_text}>Filters</Text>
          <SvgIcon name={"Filter"} width="14" height="15"/>
        </Pressable>
      </View>
      <View style={styles.filterWrapper}>
        {selectedFiltersObj.speaker?.length > 0 && (<View style={styles.tagWrapper}>
          <Text style={styles.filterTitle}>Speaker</Text>
          <View style={styles.tagItem}>
            {selectedFiltersObj.speaker?.length > 0 && <Tag title={selectedFiltersObj.speaker[0].name} id={selectedFiltersObj.speaker[0].id} filterType='speaker' onRemove={removeFilter}/>}
            {selectedFiltersObj.speaker?.length > 1 && <Tag title={`+ ${selectedFiltersObj.speaker.length - 1}`} circle={true}/>}
          </View>
          </View>)}
        {selectedFiltersObj.course?.length > 0 && (<View style={styles.tagWrapper}>
          <Text style={styles.filterTitle}>Series / Course</Text>
          <View style={styles.tagItem}>
            {selectedFiltersObj.course.length > 0 && <Tag title={selectedFiltersObj.course[0].name} id={selectedFiltersObj.course[0].id}  filterType='course' onRemove={removeFilter}/>}
            {selectedFiltersObj.course.length > 1 && <Tag title={`+${selectedFiltersObj.course.length - 1}`} circle={true} />}
          </View>
          </View>)}
          {selectedFiltersObj.topic?.length > 0 && (<View style={styles.tagWrapper}>
            <Text style={styles.filterTitle}>Topic</Text>
            <View style={styles.tagItem}>
              {selectedFiltersObj.topic.length > 0 && <Tag title={selectedFiltersObj.topic[0].name} id={selectedFiltersObj.topic[0].id} filterType='topic' onRemove={removeFilter}/>}
              {selectedFiltersObj.topic.length > 1 && <Tag title={`+ ${selectedFiltersObj.topic.length - 1}`} circle={true}/>}
            </View>
          </View>)}
      </View>
      {isFocused && (
        <PodcastList 
            podcasts={data} 
            lastPage={last_page} 
            currentPage={current_page}
            onSearch={onSearch}
            search 
            params={query} 
            isFetching={loading}
            noResult>
        </PodcastList>
      )}
      <SearchFiltersModal
        toggleFilter={toggleFilter}
        filters={selectedFilters}
        courses={courses}
        topics={topics}
        speakers={speakers}
        onClose={() => {
          setVisible(false);
        }}
        triggerFilter={triggerFilter} 
        onApply={applyFilters}
        onClear={() => {
          setSelectFilters({ speaker: [], course: [], topic: [] });
          setQuery({ ...query, speaker: [], course: [], topic: [], page: 1 });
        }}
        visible={visible}
      />
    </>
  );
}

const styles = StyleSheet.create({
  filter_wrapp: {
    alignItems: "flex-end",
  },
  filter_link: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 17,
    paddingHorizontal: 20,
  },
  link_text: {
    paddingRight: 6,
  },
  filterWrapper: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    // height: 100,
    // flex: 2
  },
  filterTitle: {
    fontSize: 12,
    fontWeight: '500',
    color: "#000",
    marginLeft: 20,
    // flex: 1
  },
  tagWrapper: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    // padding: 20,
    // paddingBottom: 0,
    // flex: 1,
    width: '50%'
  },
  tagContainer: {
    flexDirection: 'row',
    backgroundColor: '#FFF8E8',
    borderRadius: 20,
    paddingVertical: 8,
    paddingHorizontal: 8,
    marginRight: 10,
    alignItems: 'center',
    gap: 5,
    justifyContent: 'space-between',
  },
  tagText: {
    // fontFamily: 'SofiaProMedium', 
    whiteSpace: 'nowrap', 
    maxWidth: 84,
    fontWeight: '300',
    fontSize: 12
    // flex: 1
  },
  tagItem: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    flexDirection: 'row',
  }
});
