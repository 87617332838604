import MenuItem from "../MenuItem";
import { shareOnMobile } from "react-mobile-share";

 const WebShare = ({ podcast, ...rest }) => {
   return  <MenuItem
   onPress={() => { 
    shareOnMobile({
      text: `${podcast.title}, ${podcast.speaker.name}`,
      url: `${process.env.WEB_URL}/podcasts/${podcast.slug}`,
      title: podcast.title,
    })
    
   }}
   {...rest}
   chevron={false}
   color="white"
 />

 }

 export default  WebShare;